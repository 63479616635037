<template>
  <div>
    <spinner v-if="!cargando" />
    <template v-else>
      <b-table
        striped
        hover
        responsive
        :items="getProductosHistorial"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >

        <!-- COLUMNA fechaProducto -->
        <template #cell(fechaProducto)="data">
          {{ fechaFormato(data.item.fechaProducto) }}
        </template>

        <!-- COLUMNA TIPO -->
        <template #cell(tipo)="data">
          <feather-icon
            :icon="data.item.tipo === 'Salida'
              ? 'ChevronsUpIcon'
              : 'ChevronsDownIcon'"
          />
          {{ data.item.tipo }}
        </template>

        <template #cell(cantidadProducto)="data">
          <b-badge
            :variant="data.item.tipo === 'Salida'
              ? 'warning'
              : 'success'"
          >
            {{ data.item.tipo === 'Salida' ? '-' : '+' }}{{ data.item.cantidadProducto }}
          </b-badge>
        </template>

      </b-table>
      <b-alert
        v-if="getProductosHistorial.length === 0"
        variant="success"
        show
      >
        <div class="alert-body text-center">
          <span>
            El producto "{{ data.codigo }} - {{ data.nombre }}",
            - no ha sido agregado a una Salida.
            - no ha sido agregado a una OC (Aprobada).
          </span>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import {
  BTable, BAlert, BBadge,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { formatos } from '@/mixins/forms'

const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BAlert,
    BBadge,
    spinner,
  },
  data() {
    return {
      cargando: false,
      sortBy: 'fechaProducto',
      sortDesc: true,
      fields: [
        { key: 'fechaProducto', label: 'Fecha Ingresado', sortable: false },
        { key: 'tipo', label: 'Tipo', sortable: false },
        { key: 'cantidadProducto', label: 'Cantidad', sortable: false },
        { key: 'correlativo', label: 'Correlativo', sortable: false },
        { key: 'referencia', label: 'Referencia', sortable: false },
        { key: 'nombreCliente', label: 'Cliente', sortable: false },
        { key: 'nombreUsuario', label: 'Usuario', sortable: false },
      ],
    }
  },
  mixins: [formatos],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getProductosHistorial() {
      this.cargando = true
    },
  },
  computed: {
    ...mapGetters({
      getProductosHistorial: 'productos/getProductosHistorial',
    }),
  },
  mounted() {
    this.fetchProductosHistorial(this.data.id)
  },
  methods: {
    ...mapActions({ fetchProductosHistorial: 'productos/fetchProductosHistorial' }),
    ...mapMutations('productos', ['setProductosHistorial']),
    fechaFormato(fecha) {
      const date = new Date(fecha)
      const ano = date.getFullYear()
      const mes = date.getMonth()
      const dia = date.getDate()
      const respuesta = `${dia}-${mes}-${ano}`
      const set = respuesta.replace(/^(\d{4})-(\d{2})-(\d{4})$/g, '$3-$2-$1')
      return set
    },
  },
}
</script>

<style>

</style>
