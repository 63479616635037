export const formatearTelefono = {
  data() {
    return {
      telefono: null,
    }
  },
  methods: {
    formatearTelefono() {
      if (this.telefono != null) {
        const expRegTelefono = /[^+\d]/g
        const validation = expRegTelefono.test(this.telefono)
        if (validation) {
          this.telefono = this.telefono.replace(expRegTelefono, '')
        }
      }
      return this.telefono
    },
  },
}

export const formatos = {
  methods: {
    formatearFecha(fecha) {
      const date = new Date(fecha)
      // 06-06-2021 03:05
      const dia = date.getDate() > 9
        ? date.getDate()
        : `0${date.getDate()}`
      const mes = (date.getMonth() + 1) > 9
        ? (date.getMonth() + 1)
        : `0${(date.getMonth() + 1)}`
      const year = date.getFullYear()

      return `${dia}-${mes}-${year}`
    },
    formatearFechaHora(fecha) {
      const date = new Date(fecha)
      // 06-06-2021 03:05
      const dia = date.getDate() > 9
        ? date.getDate()
        : `0${date.getDate()}`
      const mes = (date.getMonth() + 1) > 9
        ? (date.getMonth() + 1)
        : `0${(date.getMonth() + 1)}`
      const year = date.getFullYear()
      const hora = date.getHours() > 9
        ? date.getHours()
        : `0${date.getHours()}`
      const minuto = date.getMinutes() > 9
        ? date.getMinutes()
        : `0${date.getMinutes()}`

      return `${dia}-${mes}-${year} ${hora}:${minuto}`
    },
    formatoPeso(number) {
      const formato = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0,
      })
      return formato.format(number)
    },
    formatoMiles(number) {
      const formato = new Intl.NumberFormat('es-CL', {
        currency: 'CLP',
        minimumFractionDigits: 0,
      })
      return formato.format(number)
    },
    soloNumeros(number) {
      const expRegNumero = /[^\d]/g
      const validation = expRegNumero.test(number)
      let numero
      if (number === undefined || validation) {
        numero = number.replace(expRegNumero, '')
      } else {
        numero = number
      }

      if (numero === '' || Number(numero) === 0) {
        numero = 0
      }
      if (numero.length >= 2) {
        if (Number(numero.charAt(0)) === 0) {
          numero = numero.slice(1)
        }
      }
      return numero
    },
    primeraMayuscula(texto) {
      return texto[0].toUpperCase() + texto.slice(1)
    },
  },
}

export const soloNumerosTramo = {
  methods: {
    ordenaMenorAMayor() {
      if (Number(this.tramoDesdeRojo) >= Number(this.tramoDesdeAmarillo)) {
        this.tramoDesdeAmarillo = Number(this.tramoDesdeRojo) + Number(1)
      }
      if (Number(this.tramoDesdeAmarillo) >= Number(this.tramoDesdeVerde)) {
        this.tramoDesdeVerde = Number(this.tramoDesdeAmarillo) + Number(1)
      }
      if (Number(this.tramoDesdeVerde) <= Number(this.tramoDesdeAmarillo)) {
        this.tramoDesdeAmarillo = Number(this.tramoDesdeVerde) - Number(1)
      }
      if (Number(this.tramoDesdeAmarillo) <= Number(this.tramoDesdeRojo)) {
        this.tramoDesdeAmarillo = Number(this.tramoDesdeRojo) + Number(1)
      }
    },
    soloNumerosTramo(event) {
      let tramo = ''
      if (event.srcElement.id === 'tramoDesdeRojo') {
        tramo = this.tramoDesdeRojo
      } else if (event.srcElement.id === 'tramoDesdeAmarillo') {
        tramo = this.tramoDesdeAmarillo
      } else if (event.srcElement.id === 'tramoDesdeVerde') {
        tramo = this.tramoDesdeVerde
      }

      if (event.srcElement.id === 'tramoDesdeRojo') {
        this.tramoDesdeRojo = tramo
      } else if (event.srcElement.id === 'tramoDesdeAmarillo') {
        this.tramoDesdeAmarillo = tramo
      } else if (event.srcElement.id === 'tramoDesdeVerde') {
        this.tramoDesdeVerde = tramo
      }
      this.ordenaMenorAMayor()
    },
  },
}

export const _ = null
